export const environment = {
    production: true,
    logErrorInConsole: true,
    environmentName: "test",
    version: require("../../package.json").version,
    httpRequestTimeoutSeconds: 120,
    utcDateFormat: "yyyy-MM-dd HH:mm:ss",
    oauthBaseAPI: "https://infill-statistics-api-qq72lvua4q-ey.a.run.app/api/v1/auth/",
    usersAPI: "https://infill-statistics-api-qq72lvua4q-ey.a.run.app/api/v1/users/",
    rolesAPI: "https://infill-statistics-api-qq72lvua4q-ey.a.run.app/api/v1/roles/",
    organizationsAPI: "https://infill-statistics-api-qq72lvua4q-ey.a.run.app/api/v1/organizations/",
    profileAPI: "https://infill-statistics-api-qq72lvua4q-ey.a.run.app/api/v1/profile/",
    resetPasswordAPI: "https://infill-statistics-api-qq72lvua4q-ey.a.run.app/api/v1/resetpassword/",
    languagesAPI: "https://infill-statistics-api-qq72lvua4q-ey.a.run.app/api/v1/languages/",
    smtpMailAccountsAPI: "https://infill-statistics-api-qq72lvua4q-ey.a.run.app/api/v1/mailaccounts/",
    mailtemplatesAPI: "https://infill-statistics-api-qq72lvua4q-ey.a.run.app/api/v1/mailtemplates/",
    systemLogMessagesAPI: "https://infill-statistics-api-qq72lvua4q-ey.a.run.app/api/v1/systemlogs/",
    systemJobPerformancesAPI: "https://infill-statistics-api-qq72lvua4q-ey.a.run.app/api/v1/systemjobs/",
    systemResourceSnapshotsAPI: "https://infill-statistics-api-qq72lvua4q-ey.a.run.app/api/v1/systemresource/",
    systemErrorsAPI: "https://infill-statistics-api-qq72lvua4q-ey.a.run.app/api/v1/systemerrors/",
    losStatisticsAPI: "https://infill-service-lostracking-gcr-qq72lvua4q-ey.a.run.app/api/v1/lostracking/",
    clickStatisticsAPI: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app/api/v1/clicktracking/"
};
